import Button from 'components/Button'
import useInvestments from 'hooks/useInvestments'
import AppLayout from 'layouts/AppLayout'
import { useState } from 'react'
import { investmentsFormId } from 'utils/make/constants'
import makeTrackings from 'utils/make/trackings'
import InvestmentCard from '../components/InvestmentCard'
import InvestmentModal from '../components/InvestmentModal'

import './InvestmentSelection.scss'

const InvestmentSelection = () => {
  const { investments } = useInvestments()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <AppLayout
      contentHeaderInfos={{
        title: 'Confira as opções de investimento',
        description:
          'Diferente opções para investir que mais combinam com o seu perfil.',
        onTrack: () => {
          makeTrackings.formBacked({
            formId: investmentsFormId,
            formName: 'investment',
            stepId: '6b116654-bf58-4712-96aa-018570d00f12',
            stepName: 'investment-subproducts',
            stepNumber: 2,
          })
        },
      }}
      contentClassName="investment-selection"
    >
      {investments.map((investment, index) => (
        <InvestmentCard
          key={investment.id}
          index={index}
          investment={investment}
        />
      ))}

      <Button
        className="investment-selection__button"
        onClick={() => {
          makeTrackings.formContinued({
            formId: investmentsFormId,
            formName: 'investment',
            stepId: '2f0e4886-0f77-4a84-ac39-645826155410',
            stepName: 'investment-subproducts',
            stepNumber: 2,
          })

          setIsModalOpen(true)
        }}
      >
        Comece a investir
      </Button>

      <InvestmentModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </AppLayout>
  )
}

export default InvestmentSelection
