import BaseCard from 'components/BaseCard'
import Button from 'components/Button'
import { navigate } from 'gatsby'
import { BASE_URL } from 'gatsby-env-variables'

import './InvestmentCard.scss'

const InvestmentCard = ({ investment, index }) => {
  const { icon, name, description, trackingName } = investment

  const handleNavigateToInvestmentDetail = () => {
    navigate(`/investimentos/detalhes?id=${investment.id}`, {
      state: { id: investment.id },
    })
  }

  return (
    <BaseCard className="investment-card">
      <img
        src={`/images/icons/${icon}.svg`}
        className="investment-card__icon"
        aria-hidden="true"
      />

      <h2 className="investment-card__title">{name}</h2>

      <p className="investment-card__description">{description}</p>

      <Button
        data-variant="ghost"
        className="investment-card__button"
        onClick={handleNavigateToInvestmentDetail}
        tracking={{
          actionOutcome: 'select-investment-subproduct',
          outboundUrl: `${BASE_URL}/investimentos/detalhes?id=${investment.id}`,
          htmlId: 'e64b051b-d043-4438-95fc-965933150da6',
          location: 'list',
          name: trackingName,
          position: ['first', 'second', 'third', 'fourth', 'fifth'][index],
        }}
      >
        Saiba mais
      </Button>
    </BaseCard>
  )
}

export default InvestmentCard
