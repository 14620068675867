const useInvestments = () => {
  const investments = [
    {
      id: 'funds',
      name: 'Fundos',
      icon: 'grid',
      description: 'Diferente opções para investir em diversos mercados.',
      trackingName: 'click-form-investments-subproducts-funds',
      details: {
        background: '/images/investments/fundos.jpg',
        name: 'Fundos de investimento',
        description:
          'Com R$ 100,00 você já pode começar a investir e ainda tem chance de aumentar o desempenho dos seus investimentos.',
        howItWorks: {
          title: 'Tipos de fundos',
          infos: [
            {
              title: 'Fundos de renda fixa',
              description:
                'Aplicam em opções de renda fixa (títulos públicos e privados, atrelados à variação da Selic, ou de índices de inflação).',
            },
            {
              title: 'Fundos de ações',
              description:
                'Indicados para o longo prazo. Oscilam mais, mas buscam um retorno maior. Investem em aplicações de renda variável.',
            },
            {
              title: 'Fundos internacionais',
              description:
                'Para quem quer investir na variação dos preços de moedas estrangeiras, como o dólar.',
            },
          ],
        },
        tracking: {
          name: 'visualization-form-investment-subproducts-funds',
          position: 'first',
          text: 'fundos-de-investimento',
        },
      },
    },
    {
      id: 'tesouro-direto',
      name: 'Tesouro Direto',
      icon: 'diamond',
      description: 'Opção simples, segura e com taxa zero para investir.',
      trackingName: 'click-form-investments-subproducts-tesouro-direto',
      details: {
        background: '/images/investments/tesouro-direto.jpg',
        howItWorks: {
          title: 'Tipos de títulos públicos',
          infos: [
            {
              title: 'Prefixados',
              description:
                'No momento da compra você sabe exatamente quanto vai receber de retorno, desde que faça o resgate apenas no vencimento do título.',
            },
            {
              title: 'Pós-fixados',
              description:
                'Você conhece os critérios de remuneração, mas só saberá o retorno total do investimento no momento do resgate.',
            },
            {
              title: 'Títulos híbridos',
              description:
                'Parte da remuneração definida no momento da compra e o restante atrelado à variação da inflação.',
            },
          ],
        },
        tracking: {
          name: 'visualization-form-investment-subproducts-tesouro-direto',
          position: 'second',
          text: 'tesouro-direto',
        },
      },
    },
    {
      id: 'cdb-renda-fixa',
      name: 'CDB e Renda fixa',
      icon: 'wallet-black',
      description: 'Invista já sabendo a remuneração.',
      trackingName: 'click-form-investments-subproducts-cdb-renda-fixa',
      details: {
        background: '/images/investments/cdb.jpg',
        howItWorks: {
          title: 'Entendendo as possibilidades',
          infos: [
            {
              title: 'Emissão bancária',
              description:
                'Ao investir ao CDBs, o investidor empresta dinheiro para o banco e recebe por isso um taxa de juros como remuneração.',
            },
            {
              title: 'Liquidez',
              description:
                'Podem ter liquidez diária, o que permite ao investidor fazer o resgate e ter o recurso disponível imediatamente a qualquer momento.',
            },
            {
              title: 'Ponto de atenção',
              description:
                'É crucial que o investidor saiba antecipadamente qual é a liquidez de um CDB para que isso não gere problemas em caso de necessidade do capital.',
            },
          ],
        },
        tracking: {
          name: 'visualization-form-investment-subproducts-cdb-renda-fixa',
          position: 'third',
          text: 'renda-fixa',
        },
      },
    },
    {
      id: 'poupanca',
      name: 'Poupança',
      icon: 'briefcase',
      description: 'Aplicação de baixo risco com rendimento mensal.',
      trackingName: 'click-form-investments-subproducts-poupanca',
      details: {
        background: '/images/investments/poupanca.jpg',
        howItWorks: {
          title: 'Pontos favoráveis',
          infos: [
            {
              title: 'Investimento fácil',
              description:
                'Não exige aplicação mínima e é uma boa alternativa para quem tem pouco capital inicial para aplicar.',
            },
            {
              title: 'Custo zero',
              description:
                'Não tem custos e oferece a garantia do FGC e pode ser usada como reserva de emergência, por conta da facilidade de acesso a liquidez.',
            },
          ],
        },
        tracking: {
          name: 'visualization-form-investment-subproducts-poupanca',
          position: 'fourth',
          text: 'poupanca',
        },
      },
    },
    {
      id: 'acoes',
      name: 'Ações',
      icon: 'globe',
      description: 'Oportunidade de ganhos no curto, médio e longo prazo.',
      trackingName: 'click-form-investments-subproducts-stocks',
      details: {
        background: '/images/investments/acoes.jpg',
        howItWorks: {
          title: 'Sobre ações',
          infos: [
            {
              title: 'O investimento',
              description:
                'Basicamente, você se torna sócio de uma empresa. Pelo valor de mercado, qualquer pessoa pode comprar uma fração dela.',
            },
            {
              title: 'Riscos',
              description:
                'O preço de ações oscila com o mercado e elas não possuem rentabilidade pré-estabelecida no momento da aplicação.',
            },
            {
              title: 'Vantagens',
              description:
                'Diferentes oportunidades de ganho, diversas alternativas de empresas para compor uma carteira variada e análises diárias dos melhores especialistas.',
            },
          ],
        },
        tracking: {
          name: 'visualization-form-investment-subproducts-stocks',
          position: 'fifth',
          text: 'acoes',
        },
      },
    },
  ]

  const getInvestmentDetail = (id) => {
    const investment = investments.find((investment) => investment.id === id)

    if (investment) {
      return { ...investment, ...investment.details }
    }

    return null
  }

  return {
    investments,
    getInvestmentDetail,
  }
}

export default useInvestments
