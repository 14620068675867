import classNames from 'classnames'

import './BaseCard.scss'

const BaseCard = ({ children, className, ...rest }) => {
  return (
    <div className={classNames('base-card', className)} {...rest}>
      {children}
    </div>
  )
}

export default BaseCard
