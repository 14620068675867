import classNames from 'classnames'
import makeTrackings from 'utils/make/trackings'

import './Button.scss'

const Button = ({ children, className, onClick, tracking, ...props }) => {
  const handleClick = (e) => {
    if (tracking) {
      const buttonText = e.target.innerText.toLowerCase().split(' ').join('-')

      makeTrackings.elementClicked({
        ...tracking,
        text: buttonText,
        elementType: 'button',
      })
    }

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <button
      className={classNames('rvtour-button', className)}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
