import Button from 'components/Button'
import { navigate } from 'gatsby'
import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import isClient from 'utils/Browser/isClient'
import { investmentsFormId } from 'utils/make/constants'
import makeTrackings from 'utils/make/trackings'
import CloseIcon from '../../../../../static/images/icons/close.svg'

import './InvestmentModal.scss'

const InvestmentModal = ({ isOpen, setIsOpen, selectedOption }) => {
  const modalRef = useRef()

  useEffect(() => {
    const body = document.querySelector('body')

    if (isOpen) {
      body.style.overflow = 'hidden'
    } else {
      body.style.removeProperty('overflow')
    }
  }, [isOpen])

  const handleCloseModal = () => {
    const menu = modalRef.current
    menu.style.transition = '0.3s'
    menu.style.transform = 'translateY(100%)'

    makeTrackings.formBacked({
      formId: investmentsFormId,
      formName: 'investment',
      stepId: '5cbfc912-481b-4ea1-a9b6-d5e0831706d6',
      stepName: 'investment-account-pop-up',
      stepNumber: 3,
    })

    setTimeout(() => {
      setIsOpen(false)
    }, 400)
  }

  const InvestmentModalLayout = (
    <div className="investment-modal">
      <div className="investment-modal__content" ref={modalRef}>
        <button
          className="investment-modal__close-button"
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </button>

        <div className="investment-modal__content-container">
          <h3 className="investment-modal__title">Falta pouco!</h3>
          <p className="investment-modal__description">
            Agora você precisa{' '}
            <strong>abrir uma conta e entrar no nosso App</strong> para
            entendermos seu perfil de investimento e mostrarmos as melhores
            opções para você!
          </p>
          <Button
            className="investment-modal__button"
            onClick={() => {
              makeTrackings.formContinued({
                formId: investmentsFormId,
                formName: 'investment',
                stepId: '46b36354-11e4-445a-9cc4-b9b7d9dd5e62',
                stepName: 'investment-account-pop-up',
                stepNumber: 3,
              })

              makeTrackings.formSubmitted({
                formId: investmentsFormId,
                formName: 'investment',
                stepId: 'ae0ec2b1-56d4-4e89-8b91-7c015f48b09b',
                stepName: 'investment-account-pop-up',
                stepNumber: 3,
              })

              makeTrackings.investment({ selected: selectedOption })

              navigate('/abrir-conta')
            }}
          >
            Abrir conta
          </Button>
        </div>
      </div>
    </div>
  )

  if (isClient() && isOpen) {
    return ReactDOM.createPortal(
      InvestmentModalLayout,
      document.getElementById('modal-root')
    )
  }

  return null
}

export default InvestmentModal
